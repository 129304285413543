import { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

import axiosInstance from './axios';

import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';

import MyMap from './Map';

function setToken(userToken: string) {
    sessionStorage.setItem('token', userToken);
}

function getToken(): string | undefined {
    const tokenString = sessionStorage.getItem('token');

    const userToken = tokenString ? tokenString : undefined;
    return userToken
}

function isTokenValid(token: string): boolean {
    // decode token
    const decodedToken = JSON.parse(atob(token.split('.')[1]));

    // check if token is expired
    if (decodedToken.exp * 1000 < Date.now()) {
        return false;
    } else {
        return true;
    }
}

function App() {

    const [screen, setScreen] = useState('auth');
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [loginAlertMessage, setLoginAlertMessage] = useState("");

    // on first render, check if there is a token in sessionStorage
    useEffect(() => {
        const token = getToken();
        if (token) {

            const tokenIsValid = isTokenValid(token);

            if (!tokenIsValid) {
                // remove from sessionStorage
                sessionStorage.removeItem('token');
                setScreen('auth');
                return;
            }

            axiosInstance.defaults.headers.common['Token'] = token;
            setScreen('map');
        }
    }, []);

    const auth = async () => {
        try {
            const res = await axiosInstance.post('/sleep/signin', { user: username, password });

            if (res.data.isError) {
                console.log("Error:", res.data.message);
                setScreen('auth');

                // show login alert for 2 seconds
                setLoginAlertMessage(res.data.message);
                setTimeout(() => setLoginAlertMessage(""), 2000)

                return;
            }

            if (res.data.token !== undefined) {
                axiosInstance.defaults.headers.common['Token'] = res.data.token;

                setToken(res.data.token);

                console.log("Logged in as", res.data.user, "with role", res.data.role)

                setScreen('map');
            } else {
                setScreen('auth');
            }

        } catch (e) {
            console.log(e);
        }
    };

    return (
        <div>
            {screen === 'auth'
                ? <Container className='m-5'>
                    <Alert className='m-3' variant='danger' show={loginAlertMessage !== ""}>
                        Login fehlgeschlagen: {loginAlertMessage}
                    </Alert>

                    <Form>
                        <Form.Group className="mb-3" controlId="formUsername">
                            <Form.Label>Username</Form.Label>
                            <Form.Control type="text" placeholder="Username" onChange={e => setUsername(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} />
                        </Form.Group>
                        <Button variant="primary" onClick={auth}>Login</Button>
                    </Form>
                </Container>
                : <MyMap />
            }
        </div>
    );
}

export default App;
