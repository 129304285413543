import axios from 'axios';

// Create an instance of the axios object with the base URL
const axiosInstance = axios.create({
    //baseURL: "http://localhost:8156",
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
    },
});

export default axiosInstance;