import React, { useState } from "react";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

function NewMarkerModal({ show, onHide, onCommentUpdate }: {
    show: boolean,
    onHide: () => void,
    onCommentUpdate: (comment: string) => void
}) {
    const [comment, setComment] = useState("");

    const updateComment = () => {
        setComment("");
        onCommentUpdate(comment);
    }

    return (
        <div
            className="modal show"
            style={{ display: 'block', position: 'initial' }}
        >
            <Modal show={show} onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Beschreibung des Platzes</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>Zum Beispiel Ort, Vorteile, Nachteile, Link zu Google Maps, ...</p>
                    <Form.Control
                        as="textarea" rows={5}
                        type="text"
                        placeholder="Beschreibung"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                    />
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={onHide}>Close</Button>
                    <Button variant="primary" onClick={updateComment}>Save changes</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default NewMarkerModal;