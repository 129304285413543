import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import { LatLngTuple, latLngBounds } from 'leaflet';
import { Icon } from "leaflet";
import { useEffect, useState } from 'react';

import axiosInstance from './axios';

import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';

import NewMarkerModal from './components/NewMarkerModal';

interface Coordinates {
    lat: number;
    long: number;
}

interface Location {
    id?: number;
    latitude: number;
    longitude: number;
    comment?: string;
}

/*
const locations: Location[] = [
    {
        id: 0,
        lat: 49.575439453125,
        long: 10.8150835037231,
        address: "Reichenfelser Straße 10",
        comment: "bla bla"
    },
    {
        id: 1,
        lat: 49.571662902832,
        long: 10.8264169692993,
        address: "Tulpenstraße 23",
        comment: "bla bla"
    },
    {
        id: 2,
        lat: 49.5807418823242,
        long: 10.8172330856323,
        address: "Schulstraße / Dörflas",
        comment: "bla bla"
    }
];
*/



const icon = new Icon({
    iconUrl: "/markers/camper.png",
    iconSize: [35, 35]
})

function MyMap() {
    const center: LatLngTuple = [49.571662902832, 10.8264169692993]

    const [markers, setMarkers] = useState<Location[]>([]);
    const [currentLocation, setCurrentLocation] = useState<Location | null>(null);

    const [doFitBounds, setDoFitBounds] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get<Location[]>("/sleep/getall")
                setMarkers(response.data);
            } catch (e) {
                console.error(e);
            }
        };

        fetchData();

    }, []);

    function createCurrentPositionMarker() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {

                    console.log(position);

                    setMarkers([...markers, {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    } as Location]);

                    setCurrentLocation({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    });

                    showModal({ lat: position.coords.latitude, long: position.coords.longitude } as Coordinates);
                },
                (error) => {
                    console.error(error);
                }
            );
        } else {
            console.error("Geolocation is not supported by this browser.");
        }
    }

    const [currentCoordsInModification, setCurrentCoordsInModification] = useState<Coordinates | null>(null);

    const [show, setShow] = useState(false);
    const closeModal = () => {
        // remove the location with currentLatInModification from the markers array
        for (let i = 0; i < markers.length; i++) {
            if (markers[i].latitude === currentCoordsInModification?.lat &&
                markers[i].longitude === currentCoordsInModification?.long) {
                console.log("removing marker")
                markers.splice(i, 1);
                break;
            }
        }
        setShow(false);
        setCurrentCoordsInModification(null);
    }
    const showModal = (coordsInModification: Coordinates) => {
        setCurrentCoordsInModification(coordsInModification);
        setShow(true);
    }

    function handleCommentUpdate(comment: string) {
        console.log("comment: " + comment)
        // update the location with currentLatInModification with the new comment
        for (let i = 0; i < markers.length; i++) {
            if (markers[i].latitude === currentCoordsInModification?.lat &&
                markers[i].longitude === currentCoordsInModification?.long) {
                console.log("updating comment")
                markers[i].comment = comment;
                break;
            }
        }
        setCurrentCoordsInModification(null);
        setShow(false);

        // TODO: do only the update; for now we just add a new marker
        axiosInstance.post<Location>("/sleep/add", {
            latitude: currentCoordsInModification?.lat,
            longitude: currentCoordsInModification?.long,
            comment: comment,
            // current date
            sleep_day: new Date().toISOString().slice(0, 10)
        })
            .then((response) => {
                //console.log(response.data);
                console.log("added new marker")
                //setMarkers([...markers, response.data]);
            }
            )
            .catch((error) => {
                console.error(error);
            }
            );
    }

    return (
        <>
            <Nav
                activeKey="/home"
                onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
            >
                <Nav.Item>
                    <Button className="m-3" onClick={createCurrentPositionMarker}>Get Current Location</Button>
                </Nav.Item>
                <Nav.Item>
                    <Button className="m-3" onClick={() => setDoFitBounds(true)}>Zoom out</Button>
                </Nav.Item>
            </Nav>



            <MapContainer
                center={center}
                zoom={15}
                scrollWheelZoom={true} >
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />


                <MyMarkers markers={markers} currentLocation={currentLocation} doFitBounds={doFitBounds} setDoFitBounds={setDoFitBounds} />

                <ManualMarker markers={markers} setMarkers={setMarkers} showModal={showModal} />

            </MapContainer >

            <NewMarkerModal
                show={show}
                onHide={closeModal}
                onCommentUpdate={handleCommentUpdate}
            />
        </>
    );
}

function ManualMarker({ markers, setMarkers, showModal }: {
    markers: Location[],
    setMarkers: (markers: Location[]) => void
    showModal: (coordsInModification: Coordinates) => void
}) {
    const map = useMap();

    useEffect(() => {
        map.on('click', (e) => {
            console.log("old number of markers: " + markers.length)

            const { lat, lng } = e.latlng;
            setMarkers([...markers, { latitude: lat, longitude: lng } as Location]);

            console.log("clicked new marker")

            console.log("new number of markers: " + markers.length)

            console.log("fly2")
            map.flyTo([lat, lng]);

            showModal({ lat, long: lng } as Coordinates);
        })
    }, [map, markers, setMarkers, showModal]);

    return null
}

function MyMarkers({ markers, currentLocation, doFitBounds, setDoFitBounds }: { markers: Location[], currentLocation?: Location | null, doFitBounds: boolean, setDoFitBounds: (doFitBounds: boolean) => void }) {
    const map = useMap();

    let markerBounds = latLngBounds([]);

    useEffect(() => {
        if (currentLocation) {
            console.log("fly")
            map.flyTo([currentLocation.latitude, currentLocation.longitude], 13);
        }
    }, [currentLocation, map]);

    useEffect(() => {
        if (doFitBounds) {
            for (let i = 0; i < markers.length; i++) {
                markerBounds.extend([markers[i].latitude, markers[i].longitude]);
            }
            if (markerBounds.isValid()) {
                map.fitBounds(markerBounds);
                setDoFitBounds(false);
            }
        }
    }, [markers, map, markerBounds, doFitBounds, setDoFitBounds]);
    //}, []); // only on the first render

    return (
        <>
            {markers.map((h, index) => (



                < Marker key={index} position={[h.latitude, h.longitude]} icon={icon} >
                    <Popup>
                        <p>{h.latitude.toFixed(6)}, {h.longitude.toFixed(6)} (lat, lng)</p>
                        <p>{h.comment}</p>
                    </Popup>
                </Marker >
            ))
            }
        </>
    );
}


export default MyMap;